import styled from "styled-components"

export const StyledImageContainer = styled("span")`
    display: flex;
    justify-content: space-evenly;

    a {
        cursor: pointer;
    }
`
